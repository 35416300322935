import React, { } from "react"

const Footer = ( ) => {

    return (
        <footer>
            <div className="container">
                <p>© Tramwaje Szczecińskie. Wszystkie prawa zastrzeżone.</p>
            </div>
        </footer>
    )

}



export default Footer;