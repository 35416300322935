import React, { } from "react"
import bannerImg from './../../images/banner.png';

const Banner = ( ) => {

    return (
        <section class="banner">
            <div className="container">
                <div className="bg-wrapper">
                    <img src={bannerImg} alt="zostań motorniczym" />
                </div>
                <div className="text-wrapper">
                    <h2>ZOSTAŃ MOTORNICZYM</h2>
                </div>
            </div>
        </section>
    )

}



export default Banner;