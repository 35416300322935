import React from 'react';
import ArrowIcon from '../../svg-components/ArrowIcon';
import PlusIcon from './../../svg-components/PlusIcon';

const Button = props => {
    let keyPressed = (e) => {
        if ( e.code === "Enter" ) {
            props.setFunction()
        }
    }
    return (
        <>
        <div className="btn-wrap">
            <div onClick={() => props.setFunction()}
                onKeyPress={ (e) => keyPressed(e)} 
                className={"btn " + ( props.className ? props.className : '') + (props.arrow ? " arrow" : "") + (props.plus ? " plus" : "")} role="button" aria-pressed="false" tabIndex="0">
                { props.title }
                { props.arrow  &&
                    <div className="arrow">
                        <ArrowIcon />
                    </div>
                }
                {props.plus &&
                    <div className="plus">
                        <PlusIcon />
                    </div>
                }
            </div>
        </div>
        </>
    )
}


export default Button;