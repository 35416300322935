import React, { useState, useEffect, useRef } from "react"
import PlusIcon from './../../svg-components/PlusIcon';

export default props => {
    let [ value, setValue ] = useState('');
    let [ error, setError ] = useState( false );
    let [ load, setLoad ] = useState( false );
    let [ errorText, setErrorText ] = useState( false );
    let [ fileTouchingBox, setFileTouchingBox ] = useState( false );

    let ref = useRef();

    useEffect(() => {
        if ( !load ) {
            setValue(props.value)
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forcealidate])

    useEffect(() => {
        if(load) {
            setValue(props.value)
        }
    }, [props.value])

    const validate = () => {
        let val = props.value
        // check if empty
        if(props.required) {
            if ( !val || val.length == 0) {
                setErrorText('To pole jest wymagane.')
                setError(true);
                return false;
            } else {
                setError(false);
            }
        }

        return true;
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && props.onClickEnter) {
            handleFileUpload(e);
        }
    }

    const handleFileUpload = (e) => {
        e.preventDefault();
        const fileSizeMB = e.target.files[0].size / 1024 / 1024;
        if(fileSizeMB > 5) {
            setError(true);
            setErrorText("Za duża waga pliku. Max 5Mb")
            setValue('');
            props.setFunction(false);
            return false;
        }else{
            setError(false);
            setErrorText("")
        }
        setValue(e.target.files[0]);
        props.setFunction(e.target.files[0]);
        // const files = [];
        
        // for(let i = 0; i < e.target.files.length; i++) {
        //     let file = e.target.files[i];
        //     files.push(file);
        // }
        // setValue(files);
        // props.setFunction(files);
    }

    const uploadFile = ( e ) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const fileSizeMB = file.size / 1024 / 1024
        if(fileSizeMB > 5) {
            setError(true);
            setErrorText("Za duża waga pliku. Max 5Mb")
            setValue('');
            props.setFunction(false);
            
            return false;
        }else{
            setError(false);
            setErrorText("")
        }
        setValue(file)
        props.setFunction(file);
        setFileTouchingBox( false )
        // const files = [];
        // if (e.dataTransfer.items) {
        //     // Use DataTransferItemList interface to access the file(s)
        //     for (var i = 0; i < e.dataTransfer.items.length; i++) {
        //       // If dropped items aren't files, reject them
        //       if (e.dataTransfer.items[i].kind === 'file') {
        //         var file = e.dataTransfer.items[i].getAsFile();
        //         files.push(file);
        //         console.log('... file[' + i + '].name = ' + file.name);
        //       }
        //     }
        //   } else {
        //     // Use DataTransfer interface to access the file(s)
        //     for (var i = 0; i < e.dataTransfer.files.length; i++) {
        //         files.push(e.dataTransfer.files[i]);
        //         console.log('... file[' + i + '].name = ' + e.dataTransfer.files[i].name);
        //     }
        //   }
        // setValue(files)
        // props.setFunction(files);
        // setFileTouchingBox( false )
    }

    return (
        <div id="img-upload"
            className="upload-area" 
            style={ fileTouchingBox ? { backgroundColor: '#fcfcfc' } : null }
            onDragOver={ ( e ) => { e.preventDefault(); 
                                    setFileTouchingBox( true ) } } 
            // onDragLeave={ ( e ) => { e.preentDefault(); setFileTouchingBox( false ) } }
            onDrop={ ( e ) => uploadFile(e) }>
            <div className={"input-wrapper-component " + ( error ? 'error ' : '') + (props.class ? props.class : 'btn-primary') + " input-upload"}>
                <p className="drag" >Jeśli masz gotowe CV, załaduj lub upuść je tutaj</p>
                { props.label &&
                    <label>{props.label}</label>
                }
                <div className="input-box">
                    <input type="file" style={{ display: 'none' }} ref={ref} onChange={ (e) => handleFileUpload(e) } onKeyDown={(e) => onKeyDown(e)} />
                    <button onClick={(e) => { e.preventDefault(); ref.current.click() }}><PlusIcon /></button>
                </div>
                {value &&
                    <p className="upload-result-text">{value.name}</p>
                }
                { error && 
                    <span className="error-text">{errorText}</span>
                }
            </div>
        </div>
        
    )
}
