import React from 'react';

const PlusIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <g clip-path="url(#clip0_517_335)">
            <path d="M15.3994 6.90418H10.0863V1.59109C10.0863 0.710306 9.37604 0 8.49526 0C7.61448 0 6.90418 0.710306 6.90418 1.59109V6.90418H1.59109C0.710306 6.90418 0 7.61448 0 8.49526C0 9.37604 0.710306 10.0863 1.59109 10.0863H6.90418V15.3994C6.90418 16.2802 7.61448 16.9905 8.49526 16.9905C9.37604 16.9905 10.0863 16.2802 10.0863 15.3994V10.0863H15.3994C16.2802 10.0863 16.9905 9.37604 16.9905 8.49526C16.9905 7.61448 16.2802 6.90418 15.3994 6.90418Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_517_335">
            <rect width="17" height="17" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

export default PlusIcon;