import React, { } from "react"

const Editor = ( { text } ) => {
    return (
        <section class="editor">
            <div className="container">
                <div className="editor-wrapper" dangerouslySetInnerHTML={ { __html: text } } />
            </div>
        </section>
    )

}



export default Editor;