import React, { useState, useEffect } from "react"
import Arrow from '../../svg-components/ArrowIcon';

const Label = ( { text, info, arrow, actionClass, clickToggle } ) => {
    let [ load, setLoad ] = useState(false);
    let [ active, setActive ] = useState(false);

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load && actionClass) {
            toggleFields();
        }
    }, [active])

    const toggleFields = () => {
        // let fields = document.getElementById(actionID).classList;
        let fields = document.getElementsByClassName(actionClass);
        for(let i = 0; i<fields.length; i++) {
            fields[i].classList.toggle('hidden');
        }
    }

    return (
        <div className="label-wrapper">
            <div className="title">
                <label style={ {marginBottom: info ? "10px" : "" }}>{text}</label>
                {arrow &&
                    <div className={`arrow-box ${active ? 'active' : ''}` } onClick={() => {setActive(!active); clickToggle && clickToggle()} }>
                        <Arrow />
                    </div>
                }
            </div>
            {info &&
                <p class="info">{info}</p>
            }
        </div>
    )

}



export default Label;