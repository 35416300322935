import React, { useState, useEffect } from 'react';


const PageNotFound = (props) => {

  return (
    <>
        <h1>STRONA NIE ISTNIEJE</h1>
    </>
  );

}

export default PageNotFound;
