import { useState, useEffect } from "react"
import CheckIcon from './../../svg-components/CheckIcon';

export default props => {
    let [ value, setValue ] = useState(false);
    let [ error, setError ] = useState( false );
    let [ load, setLoad ] = useState( false );
    let [ errorText, setErrorText ] = useState( false );
  
    useEffect(() => {
        if ( load ) {
            setLoad(false);
        }
      }, [ props.reset])

    useEffect(() => {
        if ( !load ) {
            setValue(false);
            setError(false);
            setErrorText(false);
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if ( load ) {
            validate(value);
        }
    }, [props.forceValidate])

    const validate = (val) => {

        // check if empty
        if ( props.required && !val) {
            setErrorText('To pole jest wymagane')
            setError(true);
            return false;

        } else {
            setErrorText('')
            setError(false);
        }

        return true;
    }

    const changeVal = (val) => {
        setValue(val);
        props.setFunction(val)
        validate(val);
    }

  return (
      <div className="group-checkbox" key={props.key ? props.key : ''}>
        { props.title && 
            <p className="checkbox-title">{ props.title }{ props.required ? '*' : '' }</p>
        }
        <label className={ "checkbox" + ( props.color ? ' ' + props.color : '') +  ( error ? ' error ' : '') }>
            <input type="checkbox" checked={value} onChange={ e => { changeVal(e.target.checked) }} />
            <div className="icon">
                {/* <CheckIcon /> */}
                <div className="check" />
            </div>
            <span>{ props.text }</span>
            
        </label>
        { error && !props.disableErrorText &&
            <p className="error-text">{ errorText }</p>
        }
      </div>
  )
}
