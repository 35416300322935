import React from 'react';

const Popup = props => {

    const toggle = () => {
        props.close();
    }

    const onKeyPressCloseModal = (e) => {
        if(e.key === 'Enter') {
            props.close();
        }
    }

    return (
        <div className="popup-container">
            <div className="popup" role="alert">
                <div className="popup-wrapper">
                    <div className="popup-content">
                        <div className="editor" dangerouslySetInnerHTML={{ __html: props.text }} tabIndex="0" />
                    </div>
                    <div className="popup-footer" />
                    <div className="popup-header">
                        <span tabIndex="0" role="button" onClick={() => toggle()} onKeyPress={(e) => onKeyPressCloseModal(e)}><p>X</p></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popup;