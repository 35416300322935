import React from 'react';

const ArrowIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
        <g clip-path="url(#clip0_517_352)">
            <path d="M7.51 9.50006L0.409998 2.41006C-0.140002 1.86006 -0.140002 0.960059 0.409998 0.410059C0.959998 -0.139941 1.85 -0.139941 2.41 0.410059L7.51 5.51006L12.61 0.410059C13.16 -0.139941 14.05 -0.139941 14.61 0.410059C15.16 0.960059 15.16 1.85006 14.61 2.41006L7.52 9.50006H7.51Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_517_352">
            <rect width="15.01" height="9.5" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)

export default ArrowIcon;