export let selectData = ( items ) => {
    let data = {
        'label': 'Wybierz kategorię (dla prawa jazdy)',
        'items': []
    };

    for (let i = 0; i < items.length; i++) {
    // for (let i = 0; i < 5; i++) {
        const el = items[i];

        data.items.push({
            id: i,
            title: el.name,
            value: el.name
        })
    }

    return data;
}
