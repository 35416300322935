import React, { } from "react"

const Title = ( {text, color, bold, withLine, marginTop = 0, marginBottom = 54, id="" } ) => {
    return (
        <section class={`title ${withLine ? 'with-line' : ''}`} id={id} style={{ marginTop: marginTop + "px", marginBottom: marginBottom + "px" }}>
            <div className="container">
                <div className="title-wrapper">
                    <h2 className={`${bold ? " bold" : ""} ${color == "blue" ? "blue" : ""}`}>{text}</h2>
                </div>
            </div>
        </section>
    )

}



export default Title;