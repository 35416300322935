import React, { useState, useEffect } from 'react';
import Form from './../components/form';
import Banner from './../components/banner';
import Title from './../components/title';
import Boxes from './../components/boxes';
import Editor from './../components/editor';

const Home = (props) => {
  let editor = `<ol>
    <li>Wypełnij poniższy formularz: wystarczy, że podasz nam tylko swoje dane.</li>
    <li>Możesz, ale nie musisz dodać informacje o: doświadczeniu zawodowym, wykształceniu, dodatkowych kursach. Jeśli masz gotowe CV możesz też je załączyć, ale nie jest to wymagane do przesłania zgłoszenia.</li>
    <li>Po otrzymaniu zgłoszenia skontaktujemy się z Tobą w celu zaproszenia na najbliższy kurs.</li>
    <li>Po zdanym egzaminie będziesz mógł podjąć pracę w spółce.</li>
  </ol>`;
  return (
    <>
      <Banner />
      <Title text="Skieruj karierę na dobre tory - Zostań motorniczym!" color="red" bold id="o-kursie" />
      <Boxes />
      <Title text="Jak zostać motorniczym?" color="blue" marginTop="30" marginBottom="18" />
      <Editor text={editor} />
      <Form />
    </>
  );

}

export default Home;
