import React, { useState, useEffect } from "react"
import 'moment/locale/pl';
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";

export default props => {
  let [ date, setDate ] = useState("");
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );
  let [ errorText, setErrorText ] = useState( false );

  useEffect(() => {
    if ( load ) {
        setLoad(false)
    }
  }, [ props.reset])

  useEffect(() => {
    if ( !load ) {
    //   getTodayDate();
        if(props.value) {
          setDate(props.value);
        }else{
          setDate("")
        }
        setError(false);
        setErrorText(false);
        setLoad(true);
    }
  }, [load])

  useEffect(() => {
    if(load && date) {
        props.setFunction(date);
        validate();
    }
  }, [load, date])

  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate])

  const checkDate = (addYears) => {
    addYears = parseInt(addYears);
    let dateArray = date.split("/");
    let day = parseInt(dateArray[0]);
    let month = parseInt(dateArray[1]);
    let year = parseInt(dateArray[2]);
    let newDate = new Date(year+addYears, month-1, day);
    return newDate <= new Date();
  }

  const validate = () => {
      let val = date;
      // check if empty
      if ( props.required && val.length === 0) {
          setErrorText('To pole jest wymagane');
          setError(true);
          return false;
      } else {
          setError(false);
          setErrorText("");
      }

      if(props.required && props.minAge) {
          if(!checkDate(props.minAge)) {
            setError(true);       
            setErrorText(`Wymagany wiek to ${props.minAge} lat`);
            return false;
          }else{
            setError(false);
            setErrorText("");
          }
      }

        //check length 
        // if ( props.required && val.length < props.minChar ) {
        //     setError(true);       
        //     setErrorText('Za mało znaków');
        //     return false;
        // } else {
        //     setError(false);
        // }


        //check format
        if ( props.required && props.regExp ) {
            
            let regExp = new RegExp(props.regExp);

            if(regExp.test(val)) {
                setError(false);
            } else {
                setError(true);       
                setErrorText('Zły format');
            }
        }

        return true;
        
  }

  return (
    <div className={"input-date-picker-wrap " + (props.class ? props.class + ' ' : '') + (props.type ? props.type + ' ' : '') + ( error ? 'error ' : '') }>
        {props.title &&
          <label for={ props.name }>{ props.title }{ props.required ? '*' : '' }</label>
        }
        <DatePickerInput
            onChange={(jsDate, dateString) => setDate(dateString)}
            value={date}
            className="date-input"
            locale="pl-PL"
            displayFormat="DD/MM/YYYY"
            returnFormat="DD/MM/YYYY"
            placeholder={props.placeholder}
            showOnInputClick
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        />
        { error && errorText && errorText.length > 0 && !props.disableErrorText &&
          <p className="error-text">
            { errorText }
          </p>
        }
    </div>
  )
}
